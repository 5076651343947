var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vistor" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "detail" }, [
        _c("p", { staticClass: "FirstStep" }, [
          _vm._v("第一步：获取已绑定手机" + _vm._s(_vm.phone) + "的验证码")
        ]),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "title none" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.smsCode,
                  expression: "smsCode",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "ipt",
              attrs: { placeholder: "输入验证码" },
              domProps: { value: _vm.smsCode },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.smsCode = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            !_vm.setTimeInterval
              ? _c(
                  "p",
                  {
                    staticClass: "code",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.codeClick($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.changeVerificationButton))]
                )
              : _c("p", { staticClass: "code" }, [
                  _vm._v(_vm._s(_vm.changeVerificationButton))
                ])
          ])
        ]),
        _c("p", { staticClass: "FirstStep" }, [_vm._v("第二步：设置新密码")]),
        _c("div", { staticClass: "list" }, [
          _c("p", { staticClass: "title" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.pwd,
                  expression: "pwd",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "ipt",
              attrs: { type: "password", placeholder: "输入新的密码" },
              domProps: { value: _vm.pwd },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.pwd = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]),
          _c("p", { staticClass: "title" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.pwdConfirm,
                  expression: "pwdConfirm",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "ipt",
              attrs: { type: "password", placeholder: "再次输入密码" },
              domProps: { value: _vm.pwdConfirm },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.pwdConfirm = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]),
          _vm._m(0)
        ]),
        _c("div", { staticClass: "button" }, [
          _c(
            "button",
            { staticClass: "but onSubmit", on: { click: _vm.onSubmit } },
            [_vm._v("确定")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "title none ptd" }, [
      _c("span", { staticClass: "w72" }, [_vm._v("6-16位字符，区分大小写")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }