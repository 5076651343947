<template>
  <div class="vistor">
    <div class="head">
      <div class="detail">
        <p class="FirstStep">第一步：获取已绑定手机{{ phone }}的验证码</p>
        <div class="list">
          <div class="title none">
            <input class="ipt" v-model.trim="smsCode" placeholder="输入验证码" />
            <p class="code" v-if="!setTimeInterval" @click.stop="codeClick">{{ changeVerificationButton }}</p>
            <p class="code" v-else>{{ changeVerificationButton }}</p>
          </div>
        </div>
        <p class="FirstStep">第二步：设置新密码</p>
        <div class="list">
          <p class="title">
            <input class="ipt" type="password" v-model.trim="pwd" placeholder="输入新的密码" />
          </p>
          <p class="title">
            <input class="ipt" type="password" v-model.trim="pwdConfirm" placeholder="再次输入密码" />
          </p>
          <p class="title none ptd">
            <span class="w72">6-16位字符，区分大小写</span>
          </p>
        </div>
        <div class="button">
          <button class="but onSubmit" @click="onSubmit">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast, Indicator } from 'mint-ui' // 引入提示弹窗组件
import { getPassCode, RestPwdSimple } from '@/api/login'

export default {
  data() {
    return {
      phone: '',
      times: 60,
      changeVerificationButton: '获取验证码',
      setTimeInterval: false,
      codeFalse: true, // 获取验证码防抖动参数
      smsCode: '', // 验证码
      pwd: '', // 密码
      pwdConfirm: '', // 确认密码
      errText: ''
    }
  },
  computed: {
    ...mapGetters(['mobile'])
  },
  created() {
    this.phone = this.mobile.substr(0, 3) + '****' + this.mobile.substr(7, 11)
    this.realName = this.username
  },
  methods: {
    // 获取验证码
    codeClick() {
      if (this.codeFalse) {
        this.codeFalse = false
        Indicator.open()
        getPassCode({
          mobile: this.mobile,
          smsmode: '2'
        })
          .then((res) => {
            Indicator.close()
            const { success, message } = res
            if (success) {
              this.errText = '发送成功'
              this.showError()

              const authTimetimer = setInterval(() => {
                this.codeFalse = true
                if (this.times <= 0) {
                  this.setTimeInterval = false
                  this.changeVerificationButton = '获取验证码'
                  this.flag = true
                  this.times = 60
                  clearInterval(authTimetimer)
                } else {
                  this.setTimeInterval = true
                  this.changeVerificationButton = this.times + '秒之后重新发送'
                  this.times--
                }
              }, 1000)
            } else {
              this.errText = message
              this.showError()
            }
          })
          .catch((err) => {
            Indicator.close()
            this.codeFalse = true
            this.requestFailed(err)
          })
      } else {
        this.errText = '你点的太快了'
        this.showError()
      }
    },
    onSubmit() {
      if (!this.smsCode) {
        this.errText = '请输入验证码'
        this.showError()
        return false
      } else if (this.smsCode.length !== 6) {
        this.errText = '请输入6位验证码'
        this.showError()
        return false
      }
      if (!this.pwd) {
        this.errText = '请输入密码'
        this.showError()
        return false
      } else if (this.pwd.length < 6 || this.pwd.length > 16) {
        this.errText = '请输入6到16位的密码'
        this.showError()
        return false
      }
      if (!this.pwdConfirm) {
        this.errText = '请输入确认密码'
        this.showError()
        return false
      } else if (this.pwdConfirm !== this.pwd) {
        this.errText = '确认密码与原密码不匹配'
        this.showError()
        return false
      }
      RestPwdSimple({
        username: this.mobile,
        password: this.pwdConfirm,
        smscode: this.smsCode,
        phone: this.mobile
      })
        .then((res) => {
          const { success, message } = res
          if (success) {
            this.errText = message
            this.showError()
            this.$router.go(-1)
          } else {
            Toast(message)
          }
        })
        .catch((err) => {
          Toast('修改密码失败！')
          console.log(err)
        })
    },
    // 错误请求
    requestFailed(err) {
      console.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    },
    // 弹窗提示
    showError() {
      const instance = Toast({
        message: this.errText,
        position: 'middle',
        duration: 5000
      })
      setTimeout(() => {
        instance.close()
      }, 3500)
    }
  }
}
</script>

<style lang="less" scoped>
.vistor {
  overflow: auto;
  padding-bottom: 50px;
}

.head {
  width: 100%;

  p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 36px;
  }
}

.detail {
  .FirstStep {
    margin: 15px 0 15px 15px;
    color: #666;
    font-size: 13px;
  }

  .list {
    background: #fff;
    padding: 0 15px;
    margin-bottom: 10px;

    .title {
      color: #666;
      padding-bottom: 20px;
      border-bottom: 1px solid #f0f0f0;
      font-size: 15px;
      margin-bottom: 0;
      padding-top: 20px;
      position: relative;

      .w72 {
        text-align: left;
        display: inline-block;
      }

      .binding {
        float: right;
        background: #f2f2f2;
        padding: 3px 7px;
        color: #666;
        font-size: 12px;
        border-radius: 10px;
        margin-right: 20px;
      }

      .ipt {
        font-size: 15px;
        border: none;
        text-align: left;
        vertical-align: middle;
        width: 160px;
      }

      .code {
        float: right;
        margin-right: 15px;
        color: #178cf9;
        border: 1px solid #178cf9;
        padding: 8px 14px;
        border-radius: 20px;
        vertical-align: middle;
        margin-bottom: 0;
        margin-top: -10px;
      }

      img {
        position: absolute;
        top: 25px;
        right: 2px;
      }
    }

    .ptd {
      padding: 10px;
      font-size: 12px;
      color: #666;
    }

    .none {
      border: none;
    }
  }

  .button {
    margin-top: 70px;
    text-align: center;

    .but {
      width: 250px;
      height: 38px;
      line-height: 38px;
      border-radius: 30px;
      font-size: 15px;
      border: none;
    }

    .onSubmit {
      color: #fff;
      background: #178cf9;
    }
  }
}
</style>
